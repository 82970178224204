.artwork-image {
    width: 100%;
}

.activity-map {
    height: 200px;
    width: 90%;
    margin: 0 auto;
    overflow: hidden;
}

.artwork-content {
    text-align: justify;
}

.info-row {
    margin: 10px 20px;
    font-size: large;
    text-align: justify;
}