.calendar-icon {
    margin: 10px 5px;
}

.calendar {
    /* margin: 10px auto; */
    left: 5%;
    top: 100px;
    position: absolute;
    z-index: 1000;
}