.map {
    overflow: hidden;
    width: 100%;
    height: 100%;
}


.map-tool-box {
    position: absolute;
    bottom: 60px;
    left: 0px;
    width: 90%;
    text-align: center;
    font-size: 30px;
    margin: 0 5%;
    padding: 10px;
    z-index: 1000000;
}