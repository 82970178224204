.scan-container {
    overflow: hidden;
}

.scan-icon {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 25em;
}
